/* index.js */
import 'buefy/dist/buefy.css'
import './styles/global-styles-v1.css'

document.addEventListener('DOMContentLoaded', function(event) {
  const form = document.getElementById('search-form');
  const addressInput = document.getElementById('address');

  form.onsubmit = function() {
    window.location = this.action + '#/address/' + encodeURIComponent(addressInput.value);
    return false;
  }
});